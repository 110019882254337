import * as React from "react";
import { FC } from "react";
import Layout from "../layout/Layout";
import { graphql } from "gatsby";
import projects from "../../data/projects.json"
import ProjectItemSummary from "./ProjectItemSummary";
import HeadMeta from "../layout/HeadMeta";
import { createLabel } from "../../utilities";
import NavLink from "../ui/NavLink";
import { makeButtonClass } from "../cssClasses";
import t from "../../i18n";

interface IAllProjects {
    pageContext: { slug: string }
    data: {
        images: {
            nodes: []
        }
    }
}

const AllProjects: FC<IAllProjects> = ( { data, pageContext } ) => {
    return (
        <Layout className={ "pt--header pb--lg  min-h--v100" }>
            <HeadMeta title={ pageContext.slug ? createLabel( pageContext.slug ) + " projects" : "Projects" }/>
            <nav className={ `text--center flex--center-middle my--lg` }>
                <NavLink className={ "mx--sm round--circle" } inactiveClassName={ makeButtonClass( "accent" ).outline } activeClassName={ makeButtonClass( "accent" ).fill } to={ `/projects` }>{ t( "All Projects" ) }</NavLink>
                { [ "branding", "web-design" ].map( cat =>
                    <NavLink className={ "mx--sm round--circle" } inactiveClassName={ makeButtonClass( "accent" ).outline } activeClassName={ makeButtonClass( "accent" ).fill } key={ cat } to={ `/projects/category/${ cat }` }>{ createLabel( cat ) }</NavLink>
                ) }
            </nav>
            <section className={ "projects-summary projects-summary--grid mx--auto" }>
                { projects.filter( prj => pageContext.slug ? prj.doc.categories === pageContext.slug : prj ).map( ( project ) => <ProjectItemSummary key={ project.id } project={ project } images={ data.images.nodes }/> ) }
            </section>
        </Layout>
    )
}

export default AllProjects

export const query = graphql`
    query {
        images: allFile(filter: {extension: {regex: "/jpeg|jpg|png|gif/"}}) {
            nodes {
                relativePath
                childImageSharp {
                    gatsbyImageData(
                        width: 800
                        height: 600
                        quality: 80
                        placeholder: BLURRED
                        layout: CONSTRAINED
                    )
                }
            }
        }
    }
`
